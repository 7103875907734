<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12" v-if="pageLoaded">
      <v-card-title style="background-color: transparent !important">
        <h3>
          پرونده {{ userData.user.name }} ({{ userData.user.role }})
          {{ userData.user.natCode ? "/ " + userData.user.natCode : "" }}
        </h3>
      </v-card-title>
      <v-tabs
        background-color="transparent"
        vertical
        v-model="tab"
        :style="
          deviceType == 'mobile'
            ? 'border-bottom : 1px solid rgba(0, 0, 0, 0.1) !important'
            : ''
        "
      >
        <v-tab class="tab-title first-tab-item-br"> اطلاعات پرستار </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important">
          ویرایش اطلاعات پرستار
        </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important">
          خدمات
        </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important">
          ساعات کارکرد
        </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important">
          لیست مرخصی‌ها</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important">
          پرداختی‌های پرستار</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >دریافتی‌های پرستار</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >تسویه حساب</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >فیش حقوقی</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >معاینات بدو استخدام</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >بایگانی فایل‌ها</v-tab
        >
        <v-tab class="tab-title last-tab-item-br">تغییر رمز عبور</v-tab>

        <v-tabs-items v-model="tab">
          <!-- اطلاعات پرستار -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- edit info part has been componented -->
              <person-info :job="'nurse'" :userData="userData.user" />
            </v-card>
          </v-tab-item>
          <!-- ویرایش اطلاعات پرستار -->

          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- edit info part has been componented -->
              <edit-person
                :job="'nurse'"
                @getInfo="showNurseInfo()"
                :userData="userData.user"
              />
            </v-card>
          </v-tab-item>
          <!-- خدمات -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- edit info part has been componented -->
              <person-activity :userId="userId" />
            </v-card>
          </v-tab-item>
          <!-- ساعات کارکرد -->

          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- edit info part has been componented -->
              <person-activity-time :userId="userId" />
            </v-card>
          </v-tab-item>
          <!-- لیست مرخصی‌ها -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- person leave list part has been componented -->
              <person-leave-list
                :leaves="leavesList"
                @getInfo="showNurseInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- لیست پرداخت‌ها -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- person payment part has been componented -->
              <person-payments
                :payments="paymentsList"
                @getInfo="showNurseInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- لیست دریافت ها -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- person receives part has been componented -->
              <person-receives
                :receives="receivesList"
                @getInfo="showNurseInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- تسویه حساب -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <person-checkout
                :userData="userData.user"
                :checkOut="checkOut"
                :job="'پرستار'"
                @getInfo="showNurseInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- فیش حقوقی -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 650px">
              <!-- person payment part has been componented -->
              <person-pay-slip
                :paySlips="paySlips"
                :userData="userData.user"
                @getInfo="showNurseInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- معاینات استخدام -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 650px">
              <!-- person payment part has been componented -->
              <employment-tests :userData="userData.user" />
            </v-card>
          </v-tab-item>
          <!-- لیست فایلها -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 650px">
              <!-- files part has been componented -->
              <person-files :files="filesList" @getInfo="showNurseInfo()" />
            </v-card>
          </v-tab-item>

          <!-- تغییر رمز عبور-->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- change password part has been componented -->
              <person-change-password @getInfo="showNurseInfo()" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-col>
  </div>
</template>

<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
const EditPerson = () => import("@/components/hr/EditPerson");
const personLeaveList = () => import("@/components/hr/personLeaveList");
const PersonCheckout = () => import("@/components/hr/PersonCheckout");
const PersonFiles = () => import("@/components/hr/PersonFiles");
const PersonPayments = () => import("@/components/hr/PersonPayments");
const PersonReceives = () => import("@/components/hr/PersonReceives");
const personActivityTime = () => import("@/components/hr/personActivityTime");
const personActivity = () => import("@/components/hr/personActivity");
const personInfo = () => import("@/components/hr/PersonInfo");
const personChangePassword = () => import("@/components/hr/PersonChangePass");
const PersonPaySlip = () => import("@/components/hr/PersonPaySlip");
const EmploymentTests = () => import("@/components/hr/EmploymentTests");

export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    EditPerson,
    personLeaveList,
    PersonCheckout,
    PersonFiles,
    PersonPayments,
    PersonReceives,
    personActivityTime,
    personActivity,
    personInfo,
    personChangePassword,
    PersonPaySlip,
    EmploymentTests,
  },
  data() {
    return {
      tab: null,
      dateFrom: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00:00",
      timeTo: "23:59:59",
      userData: {},
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      Filter: "",
      Busy: false,
      busyNursePercent: false,
      Fields: [
        { key: "index", label: "#" },
        { key: "serviceName", label: "خدمت" },
        { key: "date", label: "تاریخ" },
      ],
      excelFields: [
        { key: "serviceName", label: "خدمت" },
        { key: "date", label: "تاریخ" },
      ],
      Items: [],
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      pageLoaded: false,
      leavesList: [],
      filesList: [],
      paymentsList: [],
      receivesList: [],
      deviceType: "",
      checkOut: {},
      userId: "",
      paySlips: [],
    };
  },

  methods: {
    showNurseInfo() {
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/employee/getInfo", // NOTE same endpoint but show nurse info
          {
            userId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.userData.user = res.data.user;
            this.leavesList = res.data.leaves;
            this.filesList = res.data.files;
            this.paymentsList = res.data.payments;
            this.receivesList = res.data.receives;
            this.checkOut = res.data.checkOut;
            this.paySlips = res.data.payslips;
            this.vLoading = false;
            setTimeout(() => {
              this.pageLoaded = true;
            }, 200);
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },

  mounted() {
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    let previousPage = localStorage.getItem("previousPage");
    localStorage.removeItem("previousPage");
    if (previousPage == "newPaySlip") {
      this.tab = 8;
    }
    this.userId = this.$route.params.id;
    this.showNurseInfo();
  },
};
</script>
<style lang="scss">
@media only screen and (max-width: 600px) {
  .v-tabs--vertical {
    display: flex !important;
    flex-direction: column !important;
  }
  .v-tab {
    border-bottom: 1px solid #ccc;
  }
  .v-window {
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 600px) {
  .v-window {
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    margin-bottom: 10px;
  }
}
.v-tabs-slider {
  display: none;
}
</style>
